import api from "./ApiService";
import helperService from "./HelperService";

const userService = {};

userService.login = async (email, password) => {
  return await api.post("customers/User/LoginByEmail", {
    email,
    password,
  });
};

userService.loginByGuid = async (guid) => {
  return await api.post("customers/User/LoginByUserGuid", {
    userGuid: guid,
  });
};

userService.register = async (values) =>
  await api.post("customers/customer/Register", values);

userService.forgotPassword = async (values) =>
  await api.post("customers/User/ForgotPassword", values);

userService.checkGln = async (glnNo) =>
  await api.get(`customers/customer/check/glnno/${glnNo}`);

userService.checkGlnByIts = (glnNo) => api.get(`Paydas/CheckGLN/${glnNo}`);

userService.activation = (ActivationCode) =>
  api.put(`customers/customer/activation/${ActivationCode}`);

userService.resetPassword = (values) =>
  api.put(`customers/User/ChangePasswordByUserGuid`, values);

userService.profile = {
  update: async (user) => await api.put("customers/User/profile/update", user),
  changePassword: async (user) =>
    await api.post("customers/User/ChangePassword", user),
  getUsers: async (params) => {
    var queryString = helperService.generateQueryString(params);
    return await api.get(`customers/User?${queryString}`);
  },
  getUser: async (id) => await api.get(`customers/User/${id}`),
  removeUser: async (id) => await api.delete(`customers/User/${id}`),
  saveUsers: async (values) => {
    if (values.Id > 0) {
      return await api.put(`customers/User`, values);
    } else {
      return await api.post("customers/User", values);
    }
  },
  getCustomer: async (id) => await api.get(`customers/customer/${id}`),
};

userService.balance = {
  getBalances: async (params) => {
    var queryString = helperService.generateQueryString(params);
    return await api.get(`customers/CustomerBalance?${queryString}`);
  },
  getBalance: async () =>
    await api.get(`customers/Customer/GetCustomerBalance`),
};

userService.CreditCards = {
  getAll: async () => await api.get(`customers/creditcard`),
  get: async (id) => await api.get(`customers/creditcard/${id}`),
  remove: async (values) => {
    var queryString = helperService.generateQueryString(values);
    return await api.delete(`customers/creditcard/remove?${queryString}`);
  },
  update: async (values) => await api.put(`customers/creditcard`, values),
  create: async (values) => await api.post(`customers/creditcard`, values),
};

userService.dashboardTour = {
  updateDashboardTour: async (showDashboardTour) =>
    await api.get(
      `customers/Customer/UpdateDashboardTour?dashboardTour=${showDashboardTour.dashboardTourStatus}`
    ),
};

userService.Packages = {
  getPackages: async (params) => {
    var queryString = helperService.generateQueryString(params);
    return await api.get(`customers/Package?${queryString}`);
  },
  getSettings: async () => await api.get(`customers/package/settings`),
  getSettingsByCustomer: async (customerId) =>
    await api.get(`customers/package/settings/${customerId}`),
};

userService.Invoice = {
  createAddressBook: async (values) => {
    return await api.post(`invoices/InvoiceAddressBook`, values);
  },
  getAddressBook: async (customerId) => {
    return await api.get(`invoices/InvoiceAddressBook`);
  },
  getCities: async () => {
    return await api.get(`invoices/Common`);
  },
  getDistrictsandTaxOffices: async (cityId) => {
    return await api.get(`invoices/Common/${cityId}`);
  },
};

export default userService;
