import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { signOut } from "redux/actions/Auth";
import { store } from "redux/store";

const apiService = {};
const apiGateway = axios.create({
  baseURL: env.API_ENDPOINT_URL,
  timeout: 60000,
});

apiGateway.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token === null) return config;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

apiGateway.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error.response", error);
    if (error.response && error.response.status === 401) {
      store.dispatch(signOut());
      window.location = APP_PREFIX_PATH + "/giris-yap";
    } else if (error.response && error.response.status === 502) {
      window.location = "/maintenance";
    }
  }
);

apiService.get = (url) => apiGateway.get(url);
apiService.post = (url, data) => apiGateway.post(url, data);
apiService.put = (url, data) => apiGateway.put(url, data);
apiService.delete = (url, data) => apiGateway.delete(url, data);
apiService.getFile = (url) =>
  apiGateway.get(url, {
    responseType: "blob",
    timeout: 30000,
  });

export default apiService;
