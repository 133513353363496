import api from "./ApiService";
import helperService from "./HelperService";

const orderService = {
  getSearch: async (params) => {
    var queryString = helperService.generateQueryString(params);
    return await api.get(`aggregator/order?${queryString}`);
  },

  groupedStatus: async () => await api.get(`orders/order/GroupedStatus`),

  updateOrderStatusToPicking: async (values) =>
    await api.post(
      `orders/order/UpdateOrderStatusToPicking?${helperService.generateQueryString(
        values
      )}`
    ),
  updateOrderCargoCompany: async (values) => {
    var queryString = helperService.generateQueryString(values);
    return await api.put(`orders/order/updateOrderCargoCompany?${queryString}`);
  },
  cancelOrder: async (values) => {
    return await api.put(`orders/order/cancelOrder`, values);
  },
  onSplitSendWarehouse: async (values) => {
    return await api.post(`orders/order/splitSendWarehouse`, values);
  },

  sendToWise: async (orderId) => {
    return await api.post(`orders/order/SendToWise/${orderId}`);
  },

  approvedToWise: async (orderId) => {
    return await api.post(`orders/order/ApprovedToWise/${orderId}`);
  },

  getInvoicePdf: async (ettn) => {
    return await api.getFile(`Invoice/GetInvoicePdf/${ettn}`);
  },

  getClaimSearch: async (params) => {
    var queryString = helperService.generateQueryString(params);
    return await api.get(`aggregator/orderClaim?${queryString}`);
  },

  claimGroupedStatus: async () =>
    await api.get(`orders/orderClaim/GroupedStatus`),

  approveOrderClaim: async (values) => {
    return await api.post(`aggregator/orderClaim/Approved`, values);
  },

  rejectedOrderClaim: async (values) => {
    var formData = helperService.convertObjectToFormData(values);
    return await api.post(`aggregator/orderClaim/Rejected`, formData);
  },

  getAllOrders: async () => {
    return await api.get(`orders/Report/ReportSalesOrderForAll`);
  },
};

export default orderService;
