import {
  ORDER_LIST_REFRESH,
  ORDER_CANCEL_SELECTED_PRODUCTS,
  ORDER_CANCEL_SELECTED_REASON,
  ORDER_CLAIM_SELECTED_PRODUCTS,
  ORDER_CLAIM_SELECTED_REASON,
} from "redux/constants/orders/order";

const initState = {
  IsRefresh: false,
  OrderCancelSelectedProducts: [],
  OrderCancelSelectedReason: null,

  OrderClaimSelectedProducts: [],
  OrderClaimSelectedReason: null,
};

const order = (state = initState, action) => {
  switch (action.type) {
    case ORDER_LIST_REFRESH:
      return {
        ...state,
        IsRefresh: action.IsRefresh,
      };
    case ORDER_CANCEL_SELECTED_PRODUCTS:
      return {
        ...state,
        OrderCancelSelectedProducts: action.OrderCancelSelectedProducts,
      };
    case ORDER_CANCEL_SELECTED_REASON:
      return {
        ...state,
        OrderCancelSelectedReason: action.OrderCancelSelectedReason,
      };
    case ORDER_CLAIM_SELECTED_PRODUCTS:
      return {
        ...state,
        OrderClaimSelectedProducts: action.OrderClaimSelectedProducts,
      };
    case ORDER_CLAIM_SELECTED_REASON:
      return {
        ...state,
        OrderClaimSelectedReason: action.OrderClaimSelectedReason,
      };
    default:
      return state;
  }
};

export default order;
