import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import AppLayout from "layout/app-layout";
import AuthLayout from "layout/auth-layout";
import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PaymentResult from "./payment-views";
import PaymentClose from "./payment-views/close";
import { ErrorBoundary } from "react-error-boundary";
import {
  ErrorFallback,
  myErrorHandler,
} from "components/layout-components/error-boundary-components";
import NetworkError from "./error-views/network-error";
import OrderAllList from "./fuar-view/orders";

function RouteInterceptor({ element, isAuthenticated, ...rest }) {
  return isAuthenticated ? element : <Navigate to={AUTH_PREFIX_PATH} replace />;
}

export const Views = (props) => {
  const { token } = props;
  const location = useLocation();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={myErrorHandler}
      key={location.pathname}
    >
      <Routes>
        <Route path={`${AUTH_PREFIX_PATH}/*`} element={<AuthLayout />} />
        <Route path={`payment/result`} element={<PaymentResult />} />
        <Route path={`payment/close`} element={<PaymentClose />} />
        <Route path={`fuar`} element={<OrderAllList />} />
        <Route
          path="/*"
          element={
            <RouteInterceptor isAuthenticated={token} element={<AppLayout />} />
          }
        ></Route>
        <Route path={`/maintenance`} element={<NetworkError />} />
      </Routes>
    </ErrorBoundary>
  );
};

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps)(Views);
