import { SET_HUB_CUSTOMER_CONNECT } from "redux/constants/hub";

const initState = {
  hubCustomerConnect: undefined,
};

const hub = (state = initState, action) => {
  switch (action.type) {
    case SET_HUB_CUSTOMER_CONNECT:
      return {
        ...state,
        hubCustomerConnect: action.connect,
      };
    default:
      return state;
  }
};

export default hub;
