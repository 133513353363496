import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLoading from "components/shared-components/page-loading-components/auth-loading";

const Login = React.lazy(() => import("./login"));
const ForgotPassword = React.lazy(() => import("./forgot-password"));
const ResetPassword = React.lazy(() => import("./reset-password"));
const Register = React.lazy(() => import("./register"));
const Activation = React.lazy(() => import("./activation"));
const LoginByGuid = React.lazy(() => import("./login-by-guid"));

const routes = [
  {
    key: "login",
    path: "/login",
    element: <Login />,
  },
  {
    key: "login-by-guid",
    path: "/login-by-guid",
    element: <LoginByGuid />,
  },
  {
    key: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    key: "register",
    path: "/register",
    element: <Register />,
  },
  {
    key: "activation",
    path: "/activation",
    element: <Activation />,
  },
];

const AuthView = () => {
  return (
    <Suspense fallback={<AuthLoading />}>
      <Routes>
        {routes.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
        <Route path={`*`} element={<Login />} />
      </Routes>
    </Suspense>
  );
};

export default AuthView;
