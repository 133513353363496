import { ArrowLeftCircle } from "@styled-icons/bootstrap/ArrowLeftCircle";
import { store } from "redux/store";
import { setIsTourModalOpen } from "redux/actions/shared/table-data";
import CustomNextButton from "./custom-next-button";
export const customNextButton = ({
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps,
}) => {
  const last =
    currentStep === stepsLength - 2 || currentStep === stepsLength - 1;

  return (
    <CustomNextButton
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      setIsOpen={setIsOpen}
      steps={steps}
      last={last}
    />
  );
};

export const customBackButton = ({
  stepsLength,
  currentStep,
  setCurrentStep,
}) => {
  const first = currentStep === 0;
  const last = currentStep === stepsLength - 1;
  return (
    <button
      onClick={() => {
        setCurrentStep((s) => s - 1);
      }}
    >
      {first || last ? null : <ArrowLeftCircle className="w-5" />}
    </button>
  );
};

export const customBadgeContent = ({ currentStep, totalSteps }) => {
  const currentStepReal = currentStep + 1;
  const totalStepReal = totalSteps - 1;
  if (currentStep + 1 === totalSteps)
    return "Adım " + currentStep + "/" + totalStepReal;
  return "Adım " + currentStepReal + "/" + totalStepReal;
};

export const onCloseTour = (params) => {
  const { currentStep, steps, setIsOpen } = params;
  if (currentStep === steps.length - 1) {
    setIsOpen(false);
  } else if (currentStep === steps.length - 2) {
    setIsOpen(true);
  } else {
    store.dispatch(setIsTourModalOpen(true));
  }
};
