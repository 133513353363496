import {
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  USER_UPDATE,
  BALANCE_UPDATE,
  SET_AUTH_LOADING,
  DASHBOARD_TOUR_UPDATE,
  CUSTOMER_ENDDATE_UPDATE,
} from "../constants/Auth";

const initState = {
  message: "",
  showMessage: false,
  messageType: "error",
  authLoading: false,
  redirect: "",
  user: null,
  token: null,
  totalBalance: 0,
  dashboardTour: false,
  customerEndDate: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        user: action.user,
        redirect: "/",
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        messageType: action.messageType,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SET_AUTH_LOADING: {
      return {
        ...state,
        authLoading: action.authLoading,
      };
    }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        user: null,
        redirect: "/",
        totalBalance: 0,
        dashboardTour: false,
        customerEndDate: null,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        token: action.token,
      };
    }
    case USER_UPDATE: {
      return {
        ...state,
        user: action.user,
      };
    }
    case BALANCE_UPDATE: {
      return {
        ...state,
        totalBalance: action.totalBalance,
      };
    }
    case DASHBOARD_TOUR_UPDATE: {
      return {
        ...state,
        dashboardTour: action.dashboardTour,
      };
    }
    case CUSTOMER_ENDDATE_UPDATE: {
      return {
        ...state,
        customerEndDate: action.customerEndDate,
      };
    }
    default:
      return state;
  }
};

export default auth;
