import React from "react";
import "styles/app.scss";
import AppViews from "views/app-views";
import Notify from "components/shared-components/notify-components";
import PackageProvider from "components/shared-components/package-provider-components";
import AppHeader from "components/layout-components/app-layout-components/header-components";
import AppFooter from "components/layout-components/app-layout-components/footer-components";

const AppLayout = (props) => {
  return (
    <PackageProvider>
      <div className="app-container">
        <AppHeader />
        <div className="app-body">
          <div className="app-body-content">
            <AppViews />
            <Notify />
          </div>
        </div>
        <AppFooter />
      </div>
    </PackageProvider>
  );
};

export default AppLayout;
