export const CustomTooltip = (props) => {
  const { active, payload, label, selectedMonth } = props;

  if (active && payload && payload.length) {
    const { Marketplaces } = payload[0].payload;
    return (
      <div className="custom-tooltip kod-chart">
        <div className="custom-tooltip-box">
          <div className="custom-tooltip-header">
            {selectedMonth?.Month === null ? (
              <span>
                <b>{label} Ayı</b> Sipariş Özeti
              </span>
            ) : (
              <span>
               <b>{selectedMonth.Title}</b> Ayının <b>{label}.</b> Gün Özeti
              </span>
            )}
          </div>
          <div className="custom-tooltip-body">
            <div className="custom-tooltip-items">
              {Marketplaces &&
                Marketplaces.map((item, index) => {
                  return (
                    <div className="custom-tooltip-item" key={index}>
                      <span className="title">
                        <i
                          style={{
                            backgroundColor: item.MarketPlaceResponse.Color,
                          }}
                        ></i>
                        <span className="name" title={item.Name}>
                          {item.Name}
                        </span>
                      </span>
                      <span className="price">{item.FormattedTotal} TL</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};
