import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PageLoading from "components/shared-components/page-loading-components";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  customNextButton,
  customBackButton,
  customBadgeContent,
  onCloseTour,
} from "components/shared-components/tour-popover-components";
import { setHubCustomerConnection } from "redux/actions/hub";
import { signOut } from "redux/actions/Auth";
import hubConnection from "utils/signalR";
import { connect } from "react-redux";
const Dashboard = React.lazy(() => import("./dashboard"));
const Profile = React.lazy(() => import("./profile"));
const Settings = React.lazy(() => import("./settings"));
const Warehouse = React.lazy(() => import("./warehouse"));
const Products = React.lazy(() => import("./products"));
const Orders = React.lazy(() => import("./orders"));
const Support = React.lazy(() => import("./support"));
const Reports = React.lazy(() => import("./reports"));
const Questions = React.lazy(() => import("./questions"));

const routes = [
  {
    key: "dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: "profile",
    path: "/profile/*",
    element: <Profile />,
  },
  {
    key: "settings",
    path: "/settings/*",
    element: <Settings />,
  },
  {
    key: "warehouse",
    path: "/warehouse/*",
    element: <Warehouse />,
  },
  {
    key: "products",
    path: "/products/*",
    element: <Products />,
  },
  {
    key: "orders",
    path: "/orders/*",
    element: <Orders />,
  },
  {
    key: "questions",
    path: "/questions/*",
    element: <Questions />,
  },
  {
    key: "support",
    path: "/support/*",
    element: <Support />,
  },
  {
    key: "reports",
    path: "/reports/*",
    element: <Reports />,
  },
];
const AppViews = (props) => {
  const { hubCustomerConnect, signOut, isHubConnected } = props;

  const disableBody = (target) => {
    disableBodyScroll(target, {
      reserveScrollBarGap: true,
    });
  };

  const enableBody = (target) => {
    enableBodyScroll(target);
  };

  React.useEffect(() => {
    hubConnection.customerConnect();
  }, [isHubConnected]);

  React.useEffect(() => {
    if (hubCustomerConnect) {
      hubCustomerConnect.on("CustomerExpired", () => {
        signOut();
      });
    }
  }, [signOut, hubCustomerConnect]);

  return (
    <TourProvider
      steps={[]}
      showDots={false}
      showCloseButton={false}
      onClickMask={onCloseTour}
      afterOpen={disableBody}
      beforeClose={enableBody}
      maskClassName="tour-mask"
      prevButton={customBackButton}
      nextButton={customNextButton}
      badgeContent={customBadgeContent}
    >
      <Suspense fallback={<PageLoading />}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
          <Route path={`*`} element={<Dashboard />} />
        </Routes>
      </Suspense>
    </TourProvider>
  );
};

const mapStateToProps = ({ hub }) => {
  const { hubCustomerConnect } = hub;
  return { hubCustomerConnect };
};

const mapDispatchToProps = {
  setHubCustomerConnection,
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppViews);
