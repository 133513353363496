import {
  PRODUCT_SALE_SHOW_LOADING,
  PRODUCT_SALE_SET_DATA,
  PRODUCT_SALE_MATCH_REQUEST,
  PRODUCT_SALE_MATCH_SUCCESS
} from "redux/constants/product-sales/product-sale-data";

const initState = {
  saleLoading: true,
  saleData: null,
  saleMatchSuccess: false,
  saleMatchRequest: null,
};

const productSalesData = (state = initState, action) => {
  switch (action.type) {
    case PRODUCT_SALE_SHOW_LOADING:
      return {
        ...state,
        saleLoading: action.saleLoading,
      };
    case PRODUCT_SALE_SET_DATA:
      return {
        ...state,
        saleData: action.saleData,
      };
    case PRODUCT_SALE_MATCH_REQUEST:
      return {
        ...state,
        saleMatchRequest: action.saleMatchRequest,
      };
      case PRODUCT_SALE_MATCH_SUCCESS:
        return {
          ...state,
          saleMatchSuccess: action.saleMatchSuccess,
        };
    default:
      return state;
  }
};

export default productSalesData;
