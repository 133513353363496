import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  USER_UPDATE,
  BALANCE_UPDATE,
  SET_AUTH_LOADING,
  DASHBOARD_TOUR_UPDATE,
  CUSTOMER_ENDDATE_UPDATE,
  SIGNIN_BY_GUID, 
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    user,
  };
};

export const signInByGuid = (user) => {
  console.log(user);
  return {
    type: SIGNIN_BY_GUID,
    user,
  };
};

export const authenticated = (token, user) => {
  return {
    type: AUTHENTICATED,
    token,
    user,
  };
}; 

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message, messageType = "error") => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
    messageType,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const userUpdate = (user) => {
  return {
    type: USER_UPDATE,
    user,
  };
};

export const balanceUpdate = (totalBalance) => {
  return {
    type: BALANCE_UPDATE,
    totalBalance,
  };
};

export const setAuthLoading = (authLoading) => {
  return {
    type: SET_AUTH_LOADING,
    authLoading,
  };
};

export const setDashboardTour = (dashboardTour) => {
  return {
    type: DASHBOARD_TOUR_UPDATE,
    dashboardTour,
  };
};

export const setCustomerEndDate = (customerEndDate) => {
  return {
    type: CUSTOMER_ENDDATE_UPDATE,
    customerEndDate,
  };
};
