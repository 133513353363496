import { env } from "configs/EnvironmentConfig";
import React from "react";
import { connect } from "react-redux";
import { openMenu } from "redux/actions/shared/menu";
import AppLogo from "./logo-components";
import AppNavigation from "./nav-components";
import NavLink from "./nav-link-components";

const AppHeader = (props) => {
  const { isOpen, openMenu } = props;
  return (
    <div className="app-header">
      {isOpen && (
        <div
          className=" fixed w-full h-full z-50 left-0 top-0"
          onClick={() => openMenu(false)}
        ></div>
      )}
      <div className="app-header-content">
        {
          // ?TODO: Fuar için kaldırıldı, daha sonra açılacak.
          /*<HeaderEnv /> */
        }
        <div className="app-header-top">
          <AppLogo />
          <NavLink />
        </div>
        <AppNavigation />
      </div>
    </div>
  );
};

const HeaderEnv = () => {
  const [show, setShow] = React.useState("");
  let text = env.ENV === "dev" ? "DEV" : "TEST";
  return env.ENV === "prod" ? (
    <></>
  ) : (
    <div
      className={`ribbon ribbon-top-left ${env.ENV} ${show}`}
      onClick={() => setShow("hidden")}
    >
      <span>{text}</span>
    </div>
  );
};

const mapStateToProps = ({ menu }) => {
  const { isOpen } = menu;
  return { isOpen };
};
const mapDispatchToProps = {
  openMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
