import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Auth from "./Auth";
import Theme from "./Theme";
import Menu from "./shared/menu";
import Order from "./orders/order";
import Question from "./orders/question";
import Notify from "./shared/notify";
import Ticket from "./supports/ticket";
import TableFilter from "./shared/table-data";
import Marketplace from "./shared/marketplace";
import MatchesData from "./matches/match-data";
import CreditCards from "./payments/credit-cards";
import Packages from "./payments/packages";
import AdminDashboard from "./dashboard/admin-dashboard";
import CustomerDashboard from "./dashboard/customer-dashboard";
import ProductSalesData from "./product-sales/product-sale-data";
import Hub from "./hub";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["auth", "theme", "marketplaces", "adminDashboard"],
  blacklist: [
    "notify",
    "menu",
    "hub",
    "tableFilter",
    "matchesData",
    "productSalesData",
    "customerDashboard",
    "creditCards",
    "packages",
  ],
};

const rootReducer = combineReducers({
  auth: Auth,
  theme: Theme,
  notify: Notify,
  menu: Menu,
  hub: Hub,
  tableFilter: TableFilter,
  matchesData: MatchesData,
  productSalesData: ProductSalesData,
  marketplaces: Marketplace,
  ticket: Ticket,
  order: Order,
  creditCards: CreditCards,
  question: Question,
  customerDashboard: CustomerDashboard,
  adminDashboard: AdminDashboard,
  packages: Packages,
});

const reducers = persistReducer(persistConfig, rootReducer);

export default reducers;
