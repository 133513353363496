import {
    OPEN_MENU, 
  } from "redux/constants/shared/menu";
  
  export const openMenu = (isOpen) => {
    return {
      type: OPEN_MENU,
      isOpen,
    };
  }; 
  