import { Menu } from "@styled-icons/heroicons-outline";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { openMenu } from "redux/actions/shared/menu";
import "./styles.scss";

const AppLogo = (props) => {
  const { openMenu, isOpen } = props;
  return (
    <>
      <button
        className="app-mobile-menu-button"
        onClick={() => openMenu(!isOpen)}
      >
        <Menu className="w-6" />
      </button>
      <Link to={"/"} className="app-logo">
        <img src="/assets/images/Logo.png" className="w-52" alt="Pazartane" />
      </Link>
    </>
  );
};

const mapStateToProps = ({ menu }) => {
  const { isOpen } = menu;
  return { isOpen };
};
const mapDispatchToProps = {
  openMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLogo);
