import {
  SET_MARKETPLACE,
  SET_STORE,
  SET_PRODUCT_STATUS,
  SET_IS_STORE_CONNECTED,
} from "redux/constants/shared/marketplace";

const initState = {
  marketplace: [],
  stores: [],
  productStatus: [],
};

const marketplace = (state = initState, action) => {
  switch (action.type) {
    case SET_MARKETPLACE:
      return {
        ...state,
        marketplace: action.marketplace,
      };
    case SET_STORE:
      return {
        ...state,
        stores: action.stores,
      };
    case SET_IS_STORE_CONNECTED:
      const index = state.stores.findIndex((store) => store.Id === action.Id);
      const newArray = [...state.stores];
      newArray[index].IsConnected = action.IsConnected;
      return {
        ...state,
        stores: newArray,
      };
    case SET_PRODUCT_STATUS:
      return {
        ...state,
        productStatus: action.productStatus,
      };
    default:
      return state;
  }
};

export default marketplace;
