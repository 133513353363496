const dev = {
  ENV: "dev",
  API_ENDPOINT_URL: "http://testapi.pazartane.com:5001",
  CUSTOMER_HUB_URL: "http://testapi.pazartane.com:5031",
};
const stage = {
  ENV: "stage",
  API_ENDPOINT_URL: "https://public.pazartane.com",
  CUSTOMER_HUB_URL: "http://testapi.pazartane.com:5031",
};
const prod = {
  ENV: "prod",
  API_ENDPOINT_URL: "https://public.pazartane.com",
  CUSTOMER_HUB_URL: "https://public.pazartane.com",
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return dev;
    case "staging":
      return stage;
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();
