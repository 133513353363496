import { store } from "redux/store";
import {
  notifyClose,
  notifySuccess,
  notifyError,
} from "redux/actions/shared/notify";
import { connect } from "react-redux";
import { translateMessage } from "utils/helpers";

const notifyService = () => {};

const state = store.getState();

notifyService.success = (message) => {
  if (message) {
    store.dispatch(
      notifySuccess(translateMessage(message, state.theme.language))
    );
  } else {
    store.dispatch(notifySuccess("İşlem Başarılı"));
  }
};
notifyService.error = (message) => {
  if (message) { 
    store.dispatch(
      notifyError(translateMessage(message, state.theme.language))
    );
  } else {
    store.dispatch(notifyError("Bir Hata Oluştu"));
  }
};
notifyService.close = () => store.dispatch(notifyClose());

const mapStateToProps = ({ theme }) => {
  const { language } = theme;
  return { language };
};

export default connect(mapStateToProps, null)(notifyService);
