import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import ChartInfo from "components/custom-components/dashboard-components/order-total-bar-chart/chart-info";
import Loading from "components/shared-components/loading-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderService from "services/OrderService";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import reportService from "services/ReportService";
import { BarChartSelectMonth } from "redux/actions/dashboard/customer-dashboard";
import {
  CustomTooltip,
  DataFormater,
} from "components/custom-components/dashboard-components/order-total-bar-chart/bar-chart-helper";
import notifyService from "components/shared-components/notify-components/notifyService";

const OrderAllList = () => {
  const [orders, setOrders] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [showRevenue, setShowRevenue] = useState(false);
  const [showCustomerCount, setShowCustomerCount] = useState(false);
  const userType = useSelector((state) => state.auth.user.UserType);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userType !== "Admin") {
      // Redirect to /dashboard
      window.location.href = "/dashboard";
    }
  }, [userType]);

  const getAllData = async () => {
    setLoading(true);
    const response = await orderService.getAllOrders();

    if (response && response.status === 200) {
      setLoading(false);
      setOrders(response.data.Data.Products);
      setCustomerCount(response.data.Data?.Products[0]?.CustomerCount);

      setTotalRevenue(response.data.Data.Pivot);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  const hideName = (name) => {
    const words = name.split(" ");
    const hiddenWords = words.map(
      (word) => word.charAt(0) + "*".repeat(word.length - 1)
    );
    return hiddenWords.join(" ");
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("tr-TR", options);
    return formattedDate;
  };

  if (loading) {
    return (
      <div className="max-w-[1070px] min-h-screen mx-auto px-4 border-1 border-main-4-500 bg-white py-8">
        <h1 className="text-4xl font-bold text-left py-6">Tüm Siparişler</h1>
        <hr />
        <Loading show={true} height={200} />
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="max-w-[1070px] mx-auto px-4 border-1 border-main-4-500 bg-white py-8">
        <h1 className="text-4xl font-bold text-left py-6">Tüm Siparişler</h1>
        <hr />
        <h1 className="text-4xl font-bold text-left py-6">
          Sipariş Bulunamadı
        </h1>
      </div>
    );
  }

  const barchartData =
    totalRevenue?.length > 0 &&
    totalRevenue?.map((item) => {
      return {
        OrderDate: item.OrderDate,
        Price: item.Price * item.Quantity,
      };
    });

  return (
    <div className="relative">
      <div className="w-full h-full max-w-[1070px] mx-auto  sticky top-[-1px] bg-main-4-50 z-[999999]">
        <div className="flex w-full justify-between  py-8 px-4 ">
          <div className="flex flex-row gap-2 w-full items-center">
            <Link to="/dashboard">
              <img src="/assets/images/Logo.png" alt="logo" className="w-56" />
            </Link>
            <h1 className="text-3xl font-bold text-left  mt-[6px] text-main-4-500">
              Reports
            </h1>
          </div>
          <div className="flex gap-4 whitespace-nowrap">
            <FormControlLabel
              control={<Switch />}
              value={showRevenue}
              label="Toplam Ciro"
              onChange={(e) => setShowRevenue(!showRevenue)}
            />
            <FormControlLabel
              control={<Switch />}
              value={showCustomerCount}
              label="Toplam Eczane Sayısı"
              onChange={(e) => setShowCustomerCount(!showCustomerCount)}
            />
          </div>
        </div>
      </div>
      <div className="max-w-[1070px] mx-auto px-4  bg-white pb-4">
        {userType === "Admin" && (
          <>
            <br></br>
            <div
              className={`flex flex-col md:flex-row ${
                showRevenue && showCustomerCount
                  ? "justify-between"
                  : "justify-center"
              } w-full`}
            >
              {showRevenue && (
                <div className=" px-3 py-6  flex flex-col gap-4 justify-between items-center text-left bg-white">
                  <h2 className="font-bold text-3xl ">Günlük Toplam Ciro</h2>
                  <div className="w-full h-full">
                    <BarChart
                      width={showCustomerCount ? 500 : 900}
                      height={300}
                      data={barchartData || []}
                    >
                      <CartesianGrid strokeDasharray="8" stroke="#DFE2E6" />
                      <XAxis
                        dataKey="OrderDate"
                        style={{
                          fontSize: "0.6rem",
                          padding: "40px",
                          margin: "40px",
                        }}
                        stroke="#a3a3a3"
                      />
                      <YAxis
                        allowDecimals
                        dataKey="Price"
                        tickFormatter={DataFormater}
                        stroke="#a3a3a3"
                        style={{
                          fontSize: "0.8rem",
                        }}
                        label={{
                          value: "Ciro (TL)",
                          angle: -90,
                          position: "insideLeft",
                          style: {
                            textAnchor: "middle",
                            fontSize: "0.8rem",
                          },
                        }}
                      />
                      <Tooltip
                        cursor={{ fill: "#f5f7ff" }}
                        animationEasing="ease-in-out"
                      />
                      <Bar
                        dataKey="Price"
                        fill="#8884d8"
                        barSize={24}
                        barGap={0}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      />
                    </BarChart>
                  </div>
                </div>
              )}
              {showCustomerCount && (
                <div className=" px-3 py-6 min-w-[50%] flex flex-col  items-center gap-4 text-left bg-white">
                  <h2 className="font-bold text-3xl ">Toplam Eczane Sayısı</h2>
                  <span className="text-[120px]  h-full my-auto flex items-center">
                    {customerCount}
                  </span>
                </div>
              )}
            </div>
            <hr />
            <h1 className="text-4xl font-bold text-left py-6">
              Tüm Siparişler
            </h1>
            <hr />
            <div className="w-full px-3 py-6  flex flex-col justify-between bg-white">
              <div className="flex w-full justify-between px-6">
                <h2 className="font-bold text-xl">Eczane Adı</h2>
                <h2 className="font-bold text-xl">Ürün Adı</h2>
                <h2 className="font-bold text-xl">Tarih</h2>
              </div>
              {orders.map((order) => (
                <div key={order.id}>
                  <div className="w-full flex justify-between items-center py-3 px-6">
                    <p>{hideName(order.CompanyName)}</p>
                    <div className="flex flex-row items-center gap-2 justify-center">
                      <img
                        src={order.ImagePath}
                        alt="product"
                        width={50}
                        height={50}
                      />
                      <p>{order.ProductName}</p>
                    </div>
                    <p>{formatDate(order.OrderDate)}</p>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderAllList;
