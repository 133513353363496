import * as signalR from "@microsoft/signalr";
import { env } from "configs/EnvironmentConfig";
import { setHubCustomerConnection } from "redux/actions/hub";
import { store } from "redux/store";

const hubConnection = {
  connect: async (hubUrl) => {
    const connectUrl = env.CUSTOMER_HUB_URL + hubUrl;
    const hubCn = new signalR.HubConnectionBuilder()
      .withUrl(connectUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          const data = localStorage.getItem("persist:root");
          const token = JSON.parse(JSON.parse(data).auth).token;
          return token;
        },
      })
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build();
    await hubCn.start().catch((error) => console.log(error));
    return hubCn;
  },
  customerConnect: async () => {
    var connect = await hubConnection
      .connect("/hub/customers")
      .catch(({ message, stack }) => console.log({ message, stack }));
    store.dispatch(setHubCustomerConnection(connect));
  },
};

export default hubConnection;
