import React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemIcon, Popover } from "@mui/material";
import { UserCircle, Logout } from "styled-icons/heroicons-outline";
import { Wallet } from "@styled-icons/ionicons-outline";
import "./styles.scss";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { Link } from "react-router-dom";

const NavUserMenu = (props) => {
  const { user, signOut } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    signOut();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <button
        className="flex items-center justify-center bg-white sm:border sm:border-neutral-300 sm:rounded-md sm:px-4 py-2 text-sm"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <UserCircle className="sm:w-5 w-7  mr-1 text-neutral-500 font-semibold" />
        <span className="sm:block hidden text-neutral-500 font-semibold">Hesabım</span>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper
          className="user-menu-list-container"
          sx={{ width: 230, maxWidth: "100%" }}
        >
          <MenuList className="user-menu-list">
            <MenuItem className="user-info">
              <div className="pt-1">
                <div className="font-semibold text-gray-600 text-sm">
                  {user.FirstName} {user.LastName}
                </div>
                <div className="truncate text-xs text-gray-500">
                  {user.Email}
                </div>
              </div>
            </MenuItem>
            <Divider />
            <MenuItem
              component={Link}
              to={`/profile/accounts`}
              onClick={() => setAnchorEl(null)}
            >
              <ListItemIcon>
                <UserCircle className="w-6" />
              </ListItemIcon>
              <ListItemText>Profilim</ListItemText>
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/profile/balances`}
              onClick={() => setAnchorEl(null)}
            >
              <ListItemIcon>
                <Wallet className="w-6" />
              </ListItemIcon>
              <ListItemText>Cüzdanım</ListItemText>
            </MenuItem>
            {/* <MenuItem
              component={Link}
              to={`/profile`}
              onClick={() => setAnchorEl(null)}
            >
              <ListItemIcon>
                <Cog className="w-6" />
              </ListItemIcon>
              <ListItemText>Ayarlar</ListItemText>
            </MenuItem> */}
            <Divider />
            <MenuItem onClick={() => logOut()}>
              <ListItemIcon>
                <Logout className="w-6" />
              </ListItemIcon>
              <ListItemText className="text-xs">Çıkış Yap</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

const mapDispatchToProps = {
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavUserMenu);
