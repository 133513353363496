import moment from "moment";
import "moment/locale/tr";
import CryptoJS from "crypto-js";

const helperService = {};

helperService.priceFormat = (value, toFixed = 2) => {
  if (value === null || typeof value == "undefined") return "";
  var result = parseFloat(value.toString().replace(",", "."))
    .toFixed(toFixed)
    .toString()
    .replace(".", ",");

  if (result === "NaN") return "";

  return result;
};

// price format with thousand separator
helperService.priceFormatWithSeparator = (value, toFixed = 2) => {
  if (value === null || typeof value == "undefined") return "";
  var result = parseFloat(value.toString().replace(",", "."))
    .toFixed(toFixed)
    .toString()
    .replace(".", ",");
  if (result === "NaN") return "";

  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

helperService.floatParse = (value, toFixed = 2) => {
  if (value === undefined) return 0;

  var result = parseFloat(value.toString().replace(",", "."));

  if (isNaN(result)) result = 0;

  return parseFloat(result.toFixed(toFixed));
};

helperService.generateQueryString = (obj) => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      const key = encodeURIComponent(p);

      if (obj[p] instanceof Array) {
        for (var i = 0; i < obj[p].length; i++) {
          str.push(key + "=" + encodeURIComponent(obj[p][i]));
        }
      } else if (obj[p] instanceof Object) {
        for (var k in obj[p]) {
          if (obj[p].hasOwnProperty(k)) {
            str.push(key + "." + k + "=" + encodeURIComponent(obj[p][k]));
          }
        }
      } else {
        const value = encodeURIComponent(obj[p]);
        if (value !== "null" && value !== "") str.push(key + "=" + value);
      }
    }
  }
  return str.join("&");
};

helperService.setQueryString = (obj) => {
  var queryString = helperService.generateQueryString(obj);
  const { protocol, pathname, host } = window.location;
  const newUrl = `${protocol}//${host}${pathname}`;
  window.history.pushState({}, "", newUrl + "?" + queryString);
};

helperService.parseDate = (date, format) => {
  return moment(date).format(format);
};

helperService.truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  // if truncated string has a space at the end, remove it then add "..."
  if (str.substring(num, num + 1) === " ") {
    return str.substring(0, num) + "...";
  } else {
    return str.substring(0, num).replace(/\s+$/, "") + "...";
  }
};

helperService.convertObjectToFormData = (
  value,
  formData = new FormData(),
  namespace = ""
) => {
  // Değerin boş olup olmadığını kontrol ediyoruz.
  if (typeof value !== "undefined" && value !== null) {
    // Değer bir tarih ise tarih formatına dönüştürüyoruz.
    if (value instanceof Date) {
      formData.append(namespace, value.toISOString());
    }
    // Değer bir dizi ise dizi içerisindeki tüm değerleri dönüştürüyoruz.
    else if (value instanceof Array) {
      for (let i = 0; i < value.length; i++) {
        helperService.convertObjectToFormData(
          value[i],
          formData,
          namespace + "[" + i + "]"
        );
      }
    }
    // Değer bir nesne ise nesne içerisindeki tüm değerleri dönüştürüyoruz.
    else if (typeof value === "object" && !(value instanceof File)) {
      for (let propertyName in value) {
        if (value.hasOwnProperty(propertyName)) {
          helperService.convertObjectToFormData(
            value[propertyName],
            formData,
            namespace ? `${namespace}.${propertyName}` : propertyName
          );
        }
      }
    }
    // Değer bir File nesnesi ise File nesnesini formData'ya ekliyoruz.
    else if (value instanceof File) {
      formData.append(namespace, value);
    }
    // Değer bir string veya sayı ise stringi formData'ya ekliyoruz.
    else {
      formData.append(namespace, value.toString());
    }
  }
  return formData;
};

helperService.GridAccordion = {
  select: (Id, dataCount) => {
    if (Id !== null) {
      const element = document.querySelectorAll('[data-id="' + Id + '"]')[0];
      if (element === undefined) return;

      var height = dataCount * 80 + 33;
      element.style.marginBottom =
        (height > 300 ? 300 : height).toString() + "px";

      var container = document.getElementById(`order-products-${Id}`);

      if (!container) return;

      var scrollContent = document.getElementsByClassName(
        "MuiDataGrid-virtualScrollerContent"
      )[0];

      scrollContent.insertAdjacentHTML(
        "beforeend",
        "<div id='containers'>" + container.outerHTML + "</div>"
      );

      // var scroll = document.getElementsByClassName(
      //   "MuiDataGrid-virtualScroller"
      // )[0];

      // scroll.scrollTop = scrollTop;
    }
  },
  remove: () => {
    var containers = document.getElementById("containers");
    if (containers) containers.remove();

    var elements = document.getElementsByClassName("MuiDataGrid-row");
    Array.prototype.forEach.call(elements, function (el) {
      el.style.marginBottom = "0px";
    });
  },
};

const secretPass = "r48xtfAzLewdKM2a";
helperService.Crypto = {
  encrypt: (text) =>
    CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString(),

  decrypt: (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
};

helperService.makeid = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

helperService.CopyClipboard = (input) => {
  input.select();
  document.execCommand("copy");
};

export default helperService;
