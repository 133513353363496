export const TABLE_FILTER_SHOW_LOADING = "TABLE_FILTER_SHOW_LOADING";
export const TABLE_FILTER_SET = "TABLE_FILTER_SET";
export const TABLE_FILTER_PANEL_VISIBLE = "TABLE_FILTER_PANEL_VISIBLE";
export const TABLE_SET_ID = "TABLE_SET_ID";
export const TABLE_REMOVE_ID = "TABLE_REMOVE_ID";
export const TABLE_DATA = "TABLE_DATA";
export const TABLE_SET_TOUR_MODAL = "TABLE_SET_TOUR_MODAL";
export const TABLE_SET_PRODUCT_MODAL = "TABLE_SET_PRODUCT_MODAL";
export const TABLE_RESET_PRODUCT_MODAL = "TABLE_RESET_PRODUCT_MODAL";
export const SET_RANGE_DATES = "SET_RANGE_DATES";
export const TABLE_REFRESH = "TABLE_REFRESH";