import React from "react";
import "./styles.scss";
import { Alert, Snackbar } from "@mui/material";
import { connect } from "react-redux";
import { notifyClose } from "redux/actions/shared/notify";

const Notify = (props) => {
  const { open, message, variant, notifyClose } = props;

  return (
    <Snackbar
      className="notify-container"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={5000}
      onClose={notifyClose}
    >
      <Alert onClose={notifyClose} severity={variant} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = ({ notify }) => {
  const { open, message, variant } = notify;
  return { open, message, variant };
};

const mapDispatchToProps = { notifyClose };

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
