import {
  TABLE_FILTER_SHOW_LOADING,
  TABLE_FILTER_PANEL_VISIBLE,
  TABLE_FILTER_SET,
  TABLE_SET_ID,
  TABLE_REMOVE_ID,
  TABLE_SET_TOUR_MODAL,
  SET_RANGE_DATES,
  TABLE_SET_PRODUCT_MODAL,
  TABLE_RESET_PRODUCT_MODAL,
  TABLE_REFRESH,
} from "redux/constants/shared/table-data";
import helperService from "services/HelperService";

export const TableFilterShowLoading = (filterLoading) => {
  return {
    type: TABLE_FILTER_SHOW_LOADING,
    filterLoading,
  };
};
export const TableFilterPanelVisible = (filterPanelVisible) => {
  return {
    type: TABLE_FILTER_PANEL_VISIBLE,
    filterPanelVisible,
  };
};

export const TableFilterSet = (filter) => {
  helperService.setQueryString(filter);
  return {
    type: TABLE_FILTER_SET,
    filter,
  };
};

export const TableSetId = (tableId, tableContent, tableData) => {
  return {
    type: TABLE_SET_ID,
    tableId,
    tableContent,
    tableData,
  };
};

export const TableRemoveId = (removeId, tableContent) => {
  return {
    type: TABLE_REMOVE_ID,
    removeId,
    tableContent,
  };
};

export const setIsTourModalOpen = (isTourModalOpen) => {
  return {
    type: TABLE_SET_TOUR_MODAL,
    isTourModalOpen,
  };
};
export const setRangeDates = (dates) => {
  return {
    type: SET_RANGE_DATES,
    dates,
  };
};

export const TableSetProductModal = (productModal) => {
  return {
    type: TABLE_SET_PRODUCT_MODAL,
    productModal,
  };
};

export const TableResetProductModal = () => {
  return {
    type: TABLE_RESET_PRODUCT_MODAL,
  };
};

export const TableRefresh = (tableRefresh) => {
  return {
    type: TABLE_REFRESH,
    tableRefresh,
  };
};
