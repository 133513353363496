import {
  SET_SELECTED_AMOUNT,
  SET_SELECTED_CARD,
  SET_SELECTED_BANK,
  SET_PAYMENT_HTML,
  SET_CARD_FORM,
  SET_CREDIT_CARDS,
  SAVED_CARD_REFRESH,
  SUCCESS_PAYMENT,
  SET_PAYMENT_TYPE,
} from "redux/constants/payments/credit-cards";

const initState = {
  selectedAmount: "",
  selectedCard: null,
  selectedBank: null,
  paymentHtml: null,
  balanceAmounts: [100, 500, 1000, 5000],
  creditCardData: [],
  isSuccessPayment: null,
  isSavedCardRefresh: false,
  paymentType: "CreditCard",
  cardForm: {},
};

const creditCards = (state = initState, action) => {
  switch (action.type) {
    case SET_SELECTED_AMOUNT:
      return {
        ...state,
        selectedAmount: action.selectedAmount,
      };
    case SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.selectedCard,
      };
    case SET_SELECTED_BANK:
      return {
        ...state,
        selectedBank: action.selectedBank,
      };
    case SET_PAYMENT_HTML:
      return {
        ...state,
        paymentHtml: action.paymentHtml,
      };
    case SET_CARD_FORM:
      return {
        ...state,
        cardForm: action.cardForm,
      };
    case SET_CREDIT_CARDS:
      return {
        ...state,
        creditCardData: action.creditCardData,
      };
    case SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.paymentType,
      };
    case SAVED_CARD_REFRESH:
      return {
        ...state,
        isSavedCardRefresh: action.isSavedCardRefresh,
      };
    case SUCCESS_PAYMENT:
      return {
        ...state,
        isSuccessPayment: action.isSuccessPayment,
      };
    default:
      return state;
  }
};

export default creditCards;
