import {
  TABLE_FILTER_SHOW_LOADING,
  TABLE_FILTER_PANEL_VISIBLE,
  TABLE_FILTER_SET,
  TABLE_SET_ID,
  TABLE_REMOVE_ID,
  TABLE_SET_TOUR_MODAL,
  SET_RANGE_DATES,
  TABLE_SET_PRODUCT_MODAL,
  TABLE_RESET_PRODUCT_MODAL,
  TABLE_REFRESH,
} from "redux/constants/shared/table-data";
import moment from "moment";

const currentDate = moment();

const initState = {
  filterLoading: true,
  filterPanelVisible: false,
  tableId: null,
  removeId: null,
  tableContent: null,
  tableData: null,
  tableRefresh: false,
  isTourModalOpen: false,
  rangeDates: [
    moment(currentDate).subtract(30, "days").format("YYYY-MM-DD"),
    moment(currentDate).format("YYYY-MM-DD"),
  ],
  filter: {
    keyword: "",
    pageNumber: 1,
    pageSize: 12,
    filters: null,
    sort: null,
  },
  productModal: {
    id: null,
    index: 0,
    data: null,
  },
};

const tableFilter = (state = initState, action) => {
  switch (action.type) {
    case TABLE_FILTER_SHOW_LOADING:
      return {
        ...state,
        filterLoading: action.filterLoading,
      };
    case TABLE_FILTER_SET:
      return {
        ...state,
        filter: action.filter,
      };
    case TABLE_FILTER_PANEL_VISIBLE:
      return {
        ...state,
        filterPanelVisible: action.filterPanelVisible,
      };
    case TABLE_SET_ID:
      return {
        ...state,
        tableId: action.tableId,
        tableContent: action.tableContent,
        tableData: action.tableData,
      };
    case TABLE_REMOVE_ID:
      return {
        ...state,
        removeId: action.removeId,
        tableContent: action.tableContent,
      };
    case TABLE_SET_TOUR_MODAL:
      return {
        ...state,
        isTourModalOpen: action.isTourModalOpen,
      };
    case SET_RANGE_DATES:
      return {
        ...state,
        rangeDates: action.dates,
      };
    case TABLE_SET_PRODUCT_MODAL:
      return {
        ...state,
        productModal: action.productModal,
      };
    case TABLE_RESET_PRODUCT_MODAL:
      return {
        ...state,
        productModal: initState.productModal,
      };
    case TABLE_REFRESH:
      return {
        ...state,
        tableRefresh: state.tableRefresh ? false : true,
      };
    default:
      return state;
  }
};

export default tableFilter;
