import {
  SET_NOTIFY_SUCCESS,
  SET_NOTIFY_ERROR,
  SET_NOTIFY_CLOSE,
} from "redux/constants/shared/notify";

const initState = {
  open: false,
  message: "",
  variant: "success",
};

const notify = (state = initState, action) => {
  switch (action.type) {
    case SET_NOTIFY_SUCCESS:
      return {
        ...state,
        open: true,
        message: action.message,
        variant: "success",
      };
    case SET_NOTIFY_ERROR:
      return {
        ...state,
        open: true,
        message: action.message,
        variant: "error",
      };
    case SET_NOTIFY_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default notify;
