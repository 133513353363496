import React from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import paymentService from "services/PaymentService";
import { ChevronLeft } from "@styled-icons/boxicons-regular";
import userService from "services/UserService";
import { LoadingButton } from "@mui/lab";

const PaymentResult = (props) => {
  const location = useLocation();
  const [payResult, setPayResult] = React.useState(null);

  const getTransaction = React.useCallback(async () => {
    const query = new URLSearchParams(location.search);
    /*  const response = await paymentService.getTransaction(
      query.get("referenceNumber")
    ); */
    setPayResult({
      Status: query.get("Status"),
      PurchasedService: parseInt(query.get("PurchasedService")),
    }); //old version: setPayResult(response.data);
  }, [location.search]);

  React.useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <div className="payment-result-container">
      {payResult && payResult.Status === "Approved" ? (
        <SuccessResult {...{ payResult }} />
      ) : (
        <FailResult {...{ payResult }} />
      )}
    </div>
  );
};

const SuccessResult = (props) => {
  const { payResult } = props;

  return (
    /*  payResult &&  */ <div className="payment-result-content success">
      <div className="icon">
        <img src="/assets/images/icons/payment-success.png" alt="success" />
      </div>
      <div className="title">Ödeme Başarıyla Alındı!</div>
      {/*   <div className="description">
          <div>İşlem Numarası: {payResult.TransactionNumber}</div>
          <div>
            {payResult.CardBrand}-{payResult.CardIssuer}
          </div>
        </div>
        {!IsCreditCard && (
          <div className="w-4/5 text-xs text-gray-700 text-center mb-2">
            <b>'Kartı Kaydet'</b> butonuna basarak kartımın kaydedilmesini kabul
            ediyorum.
          </div>
        )} */}

      <div className="flex space-x-2">
        <a href="/payment/close?back=false" className="button-back">
          <ChevronLeft className="w-6" />
          {payResult.PurchasedService === 1
            ? " Cüzdana Git"
            : "Paket Sayfasına Git"}
        </a>
        {/*    {!IsCreditCard && (
            <LoadingButton
              className="button-save"
              loading={loading}
              onClick={SaveCard}
            >
              Kartı Kaydet
            </LoadingButton>
          )} */}
      </div>
    </div>
  );
};

const FailResult = (props) => {
  const { payResult } = props;
  return (
    /* payResult &&  */ <div className="payment-result-content warning">
      <div className="icon">
        <img src="/assets/images/icons/payment-warning.png" alt="warning" />
      </div>
      <div className="title">Bir Sorun Oluştu!</div>
      {/* <div className="description">
        <div>{payResult.StatusText}</div>
        <div>{payResult.BankErrorMessage}</div>
      </div> */}
      <a href="/payment/close?back=true" className="button-save">
        TEKRAR DENE
      </a>
    </div>
  );
};

export default PaymentResult;
