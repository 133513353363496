// import FullScreen from "./fullscreen-components";
import NavUserMenu from "./nav-user-components";
import "./styles.scss";
import TotalBalance from "./total-balance-components";

const NavLink = () => {
  return (
    <div className="app-nav-links">
      {/* <FullScreen /> */}
      <TotalBalance />
      {/* <NavSupportComponents /> */}
      <NavUserMenu />
    </div>
  );
};

export default NavLink;
