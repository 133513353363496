import { TICKET_CLOSE_ID, TICKET_SAVED } from "redux/constants/supports/ticket";

const initState = {
  ticketRemoveId: null,
  TicketIsSave: false,
};

const ticket = (state = initState, action) => {
  switch (action.type) {
    case TICKET_CLOSE_ID:
      return {
        ...state,
        ticketRemoveId: action.ticketRemoveId,
      };
    case TICKET_SAVED:
      return {
        ...state,
        TicketIsSave: action.TicketIsSave,
      };
    default:
      return state;
  }
};

export default ticket;
