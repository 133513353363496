import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { SIGNIN, SIGNIN_BY_GUID, SIGNOUT } from "redux/constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  balanceUpdate,
  setAuthLoading,
  setDashboardTour,
  setCustomerEndDate,
} from "redux/actions/Auth";
import {
  setMarketplace,
  setProductStatus,
  setStores,
} from "redux/actions/shared/marketplace";
import userService from "services/UserService";
import helperService from "services/HelperService";

// LOGIN
export function* Login() {
  yield takeEvery(SIGNIN, function* ({ user }) {
    const { email, password } = user;
    try {
      yield put(setAuthLoading(true));
      const response = yield call(userService.login, email, password);
      yield call(loginResponseHandler, response);
      yield put(setAuthLoading(false));
    } catch (err) {
      yield put(setAuthLoading(false));
      yield put(showAuthMessage("Bilgileriniz eksik veya hatalı."));
    }
  });
}

// LOGIN BY GUID
export function* LoginByGuid() {
  yield takeEvery(SIGNIN_BY_GUID, function* ({ user }) {
    const { guid } = user;
    try {
      yield put(setAuthLoading(true));
      yield put(signOutSuccess());
      const response = yield call(userService.loginByGuid, guid);
      yield call(loginResponseHandler, response);
      yield put(setAuthLoading(false));
    } catch (err) {
      yield put(setAuthLoading(false));
      yield put(showAuthMessage("Müşteri GUID'i hatalı."));
    }
  });
}

// LOGOUT
export function* LogOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage("Bilgileriniz eksik veya hatalı."));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(Login), fork(LoginByGuid), fork(LogOut)]);
}

function* loginResponseHandler(response) { 
  if (response.status === 200 && response.data.Success) {
    sessionStorage.removeItem("suppliers");
    localStorage.removeItem("ticket-subjects");
    yield put(
      authenticated(response.data.Data.AccessToken, response.data.Data.User)
    );
    yield put(setMarketplace(response.data.Data.Marketplaces));
    yield put(setStores(response.data.Data.Stores));
    yield put(setProductStatus(response.data.Data.ProductStatus));
    yield put(balanceUpdate(response.data.Data.CustomerTotalBalance));
    yield put(setDashboardTour(response.data.Data.DashboardTour));
    yield put(
      setCustomerEndDate(
        helperService.Crypto.encrypt(response.data.Data.CustomerEndDate)
      )
    );
  } else {
    yield put(showAuthMessage(response.data.Message));
  }
}
