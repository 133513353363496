import React from "react";
import { Connection } from "@styled-icons/icomoon/Connection";
const NetworkError = () => {
  /* 
  ! "back" butonu koymak sıkıntılı olabilir
   */
  return (
    <div
      className="w-full h-screen flex justify-center items-center"
      role="alert"
    >
      <div className="relative text-center items-center flex flex-col gap-10 text-[#194e91] ">
        <Connection className="absolute md:w-[600px] w-96 text-white z-[-1] bottom-[-45px]" />
        <h2 className="md:text-9xl text-6xl font-extrabold">Bakım Çalışması</h2>
        <p className="md:text-3xl text-lg font-semibold">
          Sistemimiz şu anda bakımda. Lütfen daha sonra tekrar deneyin.
        </p>

        <div className="flex gap-2 items-center">
          {/*  <p className="md:text-2xl text-base"></p>
         
           <button
            onClick={() => {
              navigate(-1);
            }}
            className="text-[#63bab0] italic md:text-2xl text-base underline"
          >
            Geri Dön
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
