import { RefreshCircle, Wallet } from "@styled-icons/ionicons-outline";
import Loading from "components/shared-components/loading-components";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { balanceUpdate } from "redux/actions/Auth";
import helperService from "services/HelperService";
import userService from "services/UserService";
import "./styles.scss";

const TotalBalance = (props) => {
  const { totalBalance, balanceUpdate, hubCustomerConnect } = props;
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (hubCustomerConnect) {
      hubCustomerConnect.on("GetCustomerBalance", (data) => {
        var customerBalance = JSON.parse(data);
        balanceUpdate(customerBalance.Total);
      });
    }
  }, [balanceUpdate, hubCustomerConnect]);

  const refreshBalance = async () => {
    setLoading(true);
    const response = await userService.balance.getBalance();
    if (response !== null && response.status === 200) {
      balanceUpdate(response.data.Data);
    }
    setLoading(false);
  };

  const [totalBalanceState, setTotalBalanceState] = React.useState({
    digit: helperService.priceFormatWithSeparator(totalBalance).split(",")[0],
    decimal: helperService.priceFormatWithSeparator(totalBalance).split(",")[1],
  });

  React.useEffect(() => {
    setTotalBalanceState({
      digit: helperService.priceFormatWithSeparator(totalBalance).split(",")[0],
      decimal: helperService
        .priceFormatWithSeparator(totalBalance)
        .split(",")[1],
    });
  }, [totalBalance]);

  return (
    <div className="total-balance-container">
      {loading && <Loading show={true} />}
      {!loading && (
        <>
          <Link to="/profile/balances">
            <Wallet className="w-5 mr-2" />
            <span className="hidden md:block font-semibold mr-2 mt-[2px]">
              Cüzdanım
            </span>
            <div className="whitespace-nowrap text-sm md:text-base font-bold text-main-4-500">
              {totalBalanceState?.digit}
              <span className="text-xs md:text-sm font-semibold text-main-4-500">
                ,{totalBalanceState?.decimal} ₺
              </span>
            </div>
          </Link>
          <button type="button" onClick={refreshBalance}>
            <RefreshCircle className="w-6 text-neutral-500 hover:text-main-3" />
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, hub }) => {
  const { totalBalance } = auth;
  const { hubCustomerConnect } = hub;

  return { totalBalance, hubCustomerConnect };
};

const mapDispatchToProps = {
  balanceUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalBalance);
