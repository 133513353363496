import {
  SET_BILLING_TYPE,
  SET_PACKAGE_TYPE,
} from "redux/constants/payments/packages";

const initState = {
  billingType: "Monthly",
  packageType: ""
};

const packages = (state = initState, action) => {
  switch (action.type) {
    case SET_BILLING_TYPE:
      return {
        ...state,
        billingType: action.billingType,
      };
    case SET_PACKAGE_TYPE:
      return {
        ...state,
        packageType: action.packageType,
      };
    default:
      return state;
  }
};

export default packages;
