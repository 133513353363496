import { SET_FULLSCREEN_ENABLED } from "redux/constants/Theme";

const initState = {
  isFullScreenEnabled: false,
  language: "tr",
};

const theme = (state = initState, action) => {
  switch (action.type) {
    case SET_FULLSCREEN_ENABLED:
      return {
        ...state,
        isFullScreenEnabled: action.isFullScreenEnabled,
      };
    default:
      return state;
  }
};

export default theme;
