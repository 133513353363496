import React from "react";
import AuthView from "views/auth-views";
import "styles/auth.scss";
import AuthSummary from "./components/auth-summary-components";

const AuthLayout = () => {
  return (
    <div className="auth-container">
      <div className="auth-content">
        <AuthView />
        <AuthSummary />
      </div>
    </div>
  );
};

export default AuthLayout;
