import { MenuItem, MenuList, Paper, Popover } from "@mui/material";
import { BoxSeam } from "@styled-icons/bootstrap";
import { DownArrow } from "@styled-icons/boxicons-solid";
import { DocumentReport } from "@styled-icons/heroicons-outline";
import { Dashboard, FileCopy } from "@styled-icons/material-outlined";
import { Storefront } from "@styled-icons/material-sharp";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { openMenu } from "redux/actions/shared/menu";
import "./styles.scss";

const data = [
  {
    key: "dashboard",
    title: "Güncel Durumum",
    icon: <Dashboard className="w-4" />,
    path: "/dashboard",
    children: [],
  },
  {
    key: "products",
    title: "Satıştaki Ürünlerim", // Before: Ürün Kataloğu
    icon: <BoxSeam className="w-4" />,
    path: "/products",
    children: [],
  },
  {
    key: "orders",
    title: "Siparişlerim",
    icon: <FileCopy className="w-4" />,
    path: "/orders/packages/created",
    children: [
      // {
      //   key: "order-created",
      //   title: "Siparişlerim",
      //   description: "Paketlemeye hazır ve kargoya verilenler",
      //   icon: <PackageIcon className="w-6" />,
      //   path: "/orders/packages/created",
      //   children: [],
      // },
      // {
      //   key: "order-cancelled",
      //   title: "İptal Edilenler",
      //   description: "İptal edilen siparişlerin takibi",
      //   icon: <Cancel className="w-6" />,
      //   path: "/orders/cancel",
      //   children: [],
      // },
      // {
      //   key: "order-returns",
      //   title: "İade İşlemleri",
      //   description: "İade işlemlerinin takibi",
      //   icon: <GroupReturn className="w-6" />,
      //   path: "/orders/returns/created",
      //   children: [],
      // },
    ],
  },
  // {
  //   key: "questions",
  //   title: "Müşteri Taleplerim",
  //   icon: <QuestionAnswer className="w-5" />,
  //   path: "/questions/waiting_for_answer",
  //   children: [],
  // },
  {
    key: "reports",
    title: "Raporlarım",
    icon: <DocumentReport className="w-5" />,
    path: "/reports",
    children: [],
  },
  {
    key: "settings",
    title: "Mağaza Entegrasyonu", // Before: Ayarlar
    icon: <Storefront className="w-5" />,
    path: "/settings/store",
    children: [],
    tour: "step-2",
  },
  /*  {
    key: "faqs",
    title: "Sıkça Sorulan Sorular",
    icon: <Question className="w-4" />,
    path: "/support/faqs",
    children: [],
  }, */
];

const AppNavigation = (props) => {
  const { isOpen, openMenu } = props;
  const [anchorEl, setAnchorEl] = React.useState({
    key: null,
    anchorEl: null,
  });

  const handleClick = (event, key) => {
    setAnchorEl({ key: key, anchorEl: event.currentTarget });
  };

  const onClose = () => {
    setAnchorEl({ key: null, anchorEl: null });
  };

  return (
    <div className={`app-header-bottom ${isOpen ? "mobile-open" : "hidden"}`}>
      <div className="app-navigation">
        {data.map((item, index) => {
          return (
            <div
              className="app-navigation-item "
              data-tour={item.tour}
              key={index}
            >
              <Menu {...{ item, handleClick, openMenu }} />
              {item.children.length > 0 && (
                <ChildMenu {...{ item, anchorEl, onClose }} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Menu = (props) => {
  const { item, handleClick, openMenu } = props;
  return item.path === "#" ? (
    <button
      className="app-navigation-item-link "
      aria-describedby={`simple-popover-${item.key}`}
      variant="contained"
      onClick={(e) => handleClick(e, item.key)}
    >
      <MenuContent {...item} />
    </button>
  ) : (
    <Link
      className="app-navigation-item-link"
      onClick={() => openMenu(false)}
      to={item.path}
    >
      <MenuContent {...item} />
    </Link>
  );
};

const MenuContent = (item) => {
  const route = window.location.pathname;
  return (
    <div
      className={` flex items-center justify-start relative
    ${item.path === route ? "text-main-4-500" : "text-neutral-500"}
    `}
    >
      <div
        className={`mr-2 ${
          item.path === route ? "text-main-4-500 " : "text-neutral-500"
        }`}
      >
        {item.icon}
      </div>
      <div className="app-navigation-item-title-wrapper">
        <div className={`app-navigation-item-title `}>
          <span className="whitespace-nowrap">{item.title}</span>
          {item.description && (
            <span className="description">{item.description}</span>
          )}
        </div>
      </div>
      {item.children.length > 0 && <DownArrow className="w-3 ml-2" />}
      {route === item.path && (
        <div
          className="active-indicator
          absolute
        bg-main-4-500
        h-[1px]
        w-full
        -bottom-2
      "
        >
          .
        </div>
      )}
    </div>
  );
};

const ChildMenu = (props) => {
  const { item, anchorEl, onClose } = props;
  // let width = (anchorEl?.anchorEl?.offsetWidth ?? 0) + 60;
  return (
    <Popover
      id={`simple-popover-${item.key}`}
      className="sub-items"
      open={anchorEl.key === item.key}
      anchorEl={anchorEl.anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Paper sx={{ maxWidth: "100%" }}>
        <MenuList className="app-navigation-sub-items">
          {item.children.map((child, index) => {
            return (
              <MenuItem
                key={index}
                component={Link}
                to={child.path}
                onClick={onClose}
                className="app-navigation-item-link"
              >
                <div className="mr-2">{child.icon}</div>
                <div className="app-navigation-item-title-wrapper">
                  <div className="app-navigation-item-title">
                    <span>{child.title}</span>
                    {child.description && (
                      <span className="description">{child.description}</span>
                    )}
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </Popover>
  );
};

const mapStateToProps = ({ menu }) => {
  const { isOpen } = menu;
  return { isOpen };
};
const mapDispatchToProps = {
  openMenu,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
