import React from "react";
import "./styles.scss";

const Loading = (props) => {
  const { show, height } = props;
  return (
    show && (
      <div className={`content-loading ${height ? height : " h-48"}`}>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};

export default Loading;
