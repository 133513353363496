import tr from "configs/locale/tr.json";

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " yıl önce";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " ay önce";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " gün önce";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " saat önce";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " dakika önce";
  }
  return Math.floor(seconds) + " saniye önce";
};

export const checkUnder1MiB = (bytes) => {
  if (!+bytes) return 0;
  const k = 1024;
  // const sizes = ["Bytes", "KiB", "MiB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return i < 2;
};

export const translateMessage = (key, language) => {
  switch (language) {
    case "tr":
      return tr[key] ? tr[key] : key;
    default:
      return key;
  }
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const ContentComponent = (props) => {
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep].content;
  return (
    <div className="flex gap-4 items-align">
      <div
        style={{
          border: "2px solid #63bab0",
          background: "white",
        }}
      >
        {/* Check if the step.content is a function or a string */}
        {typeof content === "function"
          ? content({ ...props, someOtherStuff: "Custom Text" })
          : content}
      </div>
      <div className="flex items-center">
        <button
          onClick={() => {
            if (isLastStep) {
              props.setIsOpen(false);
            } else {
              props.setCurrentStep((s) => s + 1);
            }
          }}
        >
          {isLastStep ? "x" : ">"}
        </button>
      </div>
    </div>
  );
};

export const generateRandomKey = () => {
  let length = 5,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};
