import {
  SET_NOTIFY_SUCCESS,
  SET_NOTIFY_ERROR,
  SET_NOTIFY_CLOSE,
} from "redux/constants/shared/notify";

export const notifySuccess = (message) => {
  return {
    type: SET_NOTIFY_SUCCESS,
    message,
  };
};
export const notifyError = (message) => {
  return {
    type: SET_NOTIFY_ERROR,
    message,
  };
};

export const notifyClose = () => {
  return {
    type: SET_NOTIFY_CLOSE,
  };
};
