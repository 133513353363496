import React from "react";
import { Tooltip } from "@mui/material";
import { PatchQuestion } from "@styled-icons/bootstrap";

const ChartTitle = (props) => {
  const { title, tooltip, header } = props;
  const [titleHtml, setTitleHtml] = React.useState(title);

  React.useEffect(() => {
    if (!title) return;

    const array = title.split(" ");
    if (array.length > 1) {
      var first = array.slice(0, -1).join(" ");
      var last = array.slice(-1).join(" ");
      setTitleHtml(
        <>
          <b>{first}</b> {last}
        </>
      );
    }
  }, [title]);

  return (
    <div className="chart-title flex justify-between items-center">
      {!header && <span>{titleHtml}</span>}
      {header && header}
      {tooltip && (
        <Tooltip title={tooltip}>
          <PatchQuestion className="w-4 cursor-pointer text-red-500" />
        </Tooltip>
      )}
    </div>
  );
};

export default React.memo(ChartTitle);
