import React from "react";

const AppFooter = () => {
  return (
    <div className="app-footer">
      <div className="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-white">
        <span className="font-medium text-secondary">Pazartane © 2022</span>
      </div>
    </div>
  );
};

export default AppFooter;
