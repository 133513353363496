import React from "react";
import "./styles.scss";
import styled, { keyframes } from "styled-components";
import { LoaderCircle } from "styled-icons/boxicons-regular";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(LoaderCircle)`
  animation: 2s linear ${spin} infinite;
`;

const AuthLoading = () => {
  return (
    <div className="auth-form-container">
      <div className="auth-form-content flex items-center justify-center h-full">
        <SpinningIcon className="w-5 text-gray-600 mr-1" />
        Lütfen Bekleyiniz...
      </div>
    </div>
  );
};

export default AuthLoading;
