import { MATCH_SET_DATA } from "redux/constants/matches/match-data";

const initState = {
  matchData: null,
};

const matchesData = (state = initState, action) => {
  switch (action.type) {
    case MATCH_SET_DATA:
      return {
        ...state,
        matchData: action.matchData,
      };
    default:
      return state;
  }
};

export default matchesData;
