import {
  SELECT_STORE,
  SET_ONBOARDING_ACTIVE,
  SET_ONBOARDING_STEP,
} from "redux/constants/dashboard/customer-dashboard";

const initState = {
  selectedStore: { Id: null, Name: "" },
  onboardingStep: 0,
  onboardingActive: true,
};

const customerDashboard = (state = initState, action) => {
  switch (action.type) {
    case SELECT_STORE:
      return {
        ...state,
        selectedStore: action.selectedStore,
      };
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStep: action.onboardingStep,
      };
    case SET_ONBOARDING_ACTIVE:
      return {
        ...state,
        onboardingActive: action.onboardingActive,
      };
    default:
      return state;
  }
};

export default customerDashboard;
