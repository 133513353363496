import React from "react";
import { useLocation } from "react-router";
import userService from "services/UserService";
import { store } from "redux/store";
import { setDashboardTour } from "redux/actions/Auth";
import { TableSetId } from "redux/actions/shared/table-data";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";

const CustomNextButton = ({
  currentStep,
  setCurrentStep,
  setIsOpen,
  steps,
  last,
}) => {
  const location = useLocation();
  const dashboardTour = store.getState().auth.dashboardTour;
  const tableId = store.getState().tableFilter.tableId;
  const saleData = store.getState().productSalesData.saleData;
   
  return (
    <button
      id="tour-next-button"
      onClick={() => {
        if (currentStep === 0 && tableId === null && !saleData) {
          if (location.pathname === "/" || location.pathname === "/dashboard") {
            setCurrentStep((s) => (s === steps?.length - 2 ? 0 : s + 1));
          }
          if (location.pathname === "/settings/store") {
            store.dispatch(TableSetId(0, "StoreModal"));
          }
          if (location.pathname === "/support/tickets") {
            store.dispatch(TableSetId(0, "TicketModal"));
          }
          if (location.pathname === "/settings/einvoice") {
            store.dispatch(TableSetId(0, "EInvoiceModal"));
          }
          if (location.pathname === "/warehouse/products") {
            document.querySelectorAll(".store-icon:not(.matched)")[0].click();
          }
        } else if (last) {
          setIsOpen(false);
          if (dashboardTour) {
            userService.dashboardTour.updateDashboardTour({
              dashboardTourStatus: false,
            });
            store.dispatch(setDashboardTour(false));
          }
        } else {
          setCurrentStep((s) => (s === steps?.length - 2 ? 0 : s + 1));
        }
      }}
    >
      {last ? "Bitir!" : <ArrowRightCircle className="w-5" />}
    </button>
  );
};

export default CustomNextButton;
