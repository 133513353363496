import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
import ChartTitle from "../chart-title-components";
import moment from "moment";
import { Tooltip } from "@mui/material";

const ChartInfo = (props) => {
  const { selectedBarChartMonth, data } = props;

  return (
    <div>
      <ChartTitle
        title={`${
          selectedBarChartMonth?.Month === null
            ? moment().year()
            : `${selectedBarChartMonth.Title} Ayı`
        } SİPARİŞ ÖZETİ`}
      /> 
      <div className="marketplaces-container">
        <div className="marketplace-items">
          {data &&
            data.map((item, index) => {
              return (
                <div className="marketplace-item" key={index}>
                  <div className="info">
                    <div className="title">{item.Name}</div>
                    <div className="price">{item.FormattedTotal} TL</div>
                  </div>
                  <Tooltip title={`${item.FormattedRate}%`}>
                    <div className="bar-chart">
                      <span
                        style={{
                          backgroundColor: item.MarketPlaceResponse.Color,
                          width: `${item.Rate}%`,
                        }}
                      ></span>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminDashboard }) => {
  const { selectedBarChartMonth } = adminDashboard;
  return { selectedBarChartMonth };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChartInfo);
