import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import helperService from "services/HelperService";

const PackageProvider = ({ children, customerEndDate }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (
      customerEndDate &&
      location.pathname !== "/profile/packages" &&
      location.pathname !== "/profile/packages/transactions"
    ) {
      let endDate = new Date(helperService.Crypto.decrypt(customerEndDate));
      let nowDate = new Date();

      let remainingTime = Math.ceil(endDate.getTime() - nowDate.getTime());
      if (remainingTime < 0) window.location.href = "/profile/packages";
    }
  }, [customerEndDate, location]);
  return children;
};

const mapStateToProps = ({ auth }) => {
  const { customerEndDate } = auth;
  return { customerEndDate };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PackageProvider);
