import axios from "axios";
import api from "./ApiService";

const paymentService = {
  balanceCreditCard: async (values) => {
    values.expireMonth = values.expiredDate.split("/")[0];
    values.expireYear = values.expiredDate.split("/")[1];
    return await api.post(`payments/Payment/balance/creditcard`, values);
  },
  balanceSavedCard: async (values) => {
    return await api.post(`payments/Payment/balance/savedcard`, values);
  },
  balanceTransferOrEft: async (values) => {
    return await api.post(`payments/Payment/balance/transferoreft`, values);
  },
  getTransaction: async (ReferenceNumber) => {
    return await api.get(`payments/Payment/transaction/${ReferenceNumber}`);
  },
  packageCreditCard: async (values) => {
    return await api.post(`payments/Payment/package/creditcard`, values);
  },
  packageSavedCard: async (values) => {
    return await api.post(`payments/Payment/package/savedcard`, values);
  },
  // PAYTR
  sendPayTrRequest: async (values) => {
    const data = await api.post("payments/Payment/paytr/request", values);
    return data;
  },
  sendPayTrRequestByOrdek: async (values) => {
    const data = await axios.post(
      "https://api.datasonik.com/api/v1/PayTr/paytr/request",
      values,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  },
};

paymentService.bankAccounts = {
  getAll: async () => {
    return await api.get("payments/bankaccount");
  },
};

export default paymentService;
