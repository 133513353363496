export const SIGNIN = "SIGNIN";
export const SIGNIN_BY_GUID = "SIGNIN_BY_GUID";
export const AUTHENTICATED = "AUTHENTICATED"; 
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const USER_UPDATE = "USER_UPDATE";
export const BALANCE_UPDATE = "BALANCE_UPDATE";
export const DASHBOARD_TOUR_UPDATE = "DASHBOARD_TOUR_UPDATE";
export const CUSTOMER_ENDDATE_UPDATE = "CUSTOMER_ENDDATE_UPDATE";
