import {
  SELECT_TIME_PERIOD,
  BAR_CHART_SELECT_MONTH,
} from "redux/constants/dashboard/customer-dashboard";

const initState = {
  selectedTimePeriod: { Id: 1, Name: "Bugünün" },
  selectedBarChartMonth: { Month: null, Title: "" },
};

const adminDashboard = (state = initState, action) => {
  switch (action.type) {
    case SELECT_TIME_PERIOD:
      return {
        ...state,
        selectedTimePeriod: action.selectedTimePeriod,
      };
    case BAR_CHART_SELECT_MONTH:
      return {
        ...state,
        selectedBarChartMonth: action.selectedBarChartMonth,
      };
    default:
      return state;
  }
};

export default adminDashboard;
