import { Envelope } from "@styled-icons/bootstrap";
import "./styles.scss";
// import {  Telephone } from "@styled-icons/bootstrap";

const contactData = [
  {
    key: "mail",
    icon: Envelope,
    text: "destek@pazartane.com",
    link: "mailto:destek@pazartane.com",
  },
  /* {
    key: "phone",
    icon: Telephone,
    text: "0 850 396 07 07",
    link: "#",
  }, */
  /*   {
    key: "whatsapp",
    icon: Whatsapp,
    text: "Whatsapp Destek",
    link: "#",
  }, */
];

const AuthSummary = () => {
  return (
    <div className="auth-summary-container">
      <AuthSummarySvg />
      <div className="auth-summary-content">
        <div className="title">
          <div className="mb-1">Eczanelere Özel</div>
          <div>Pazaryeri Entegrasyonu</div>
        </div>
        <div className="description">
          Pazartane, Dropshipping yöntemiyle çalışan ve sadece eczanelerimize
          özel bir pazaryeri entegrasyon yazılımıdır.
        </div>
        <div className="store-content">
          <AuthStoreForm />
        </div>
        <div className="auth-contact-container">
          <div className="contact-text">
            Bize ulaşabileceğiniz iletişim kanalları
          </div>
          <div className="auth-contact-content">
            {contactData.map((item) => {
              return (
                <div key={item.key} className="auth-contact-item">
                  <item.icon className="w-3 mr-2" />
                  <a href={item.link}>{item.text}</a>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="flex flex-col text-gray-100 gap-2 mt-8">
          <p className="font-light text-sm">
            Bize ulaşabileceğiniz iletişim kanalları
          </p>
          <div className="contact-option mt-2">
            <div>
              <Envelope className="contact-icon" />
            </div>
            <div>
              <a href="mailto:destek@pazartane.com">
                <p className=""> destek@pazartane.com</p>
              </a>
            </div>
          </div>
          <div className="contact-option">
            <div>
              <Telephone className="contact-icon" />
            </div>
            <div>
              <p className=""> 0 (555) 555 55 55</p>
            </div>
          </div>

          <div className="contact-option cursor-pointer">
            <div>
              <Whatsapp className="contact-icon text-[#25d366]" />
            </div>
            <div>
              <p className="mt-[2px]">Pazartane Destek</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const AuthSummarySvg = () => {
  return (
    <>
      <svg
        viewBox="0 0 960 540"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMax slice"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 pointer-events-none"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeWidth="100"
          className="text-gray-700 opacity-25"
        >
          <circle r="234" cx="196" cy="23"></circle>
          <circle r="234" cx="790" cy="491"></circle>
        </g>
      </svg>
      <svg
        viewBox="0 0 220 192"
        width="220"
        height="192"
        fill="none"
        className="absolute -top-16 -right-16 text-gray-700"
      >
        <defs>
          <pattern
            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
          </pattern>
        </defs>
        <rect
          width="220"
          height="192"
          fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
        ></rect>
      </svg>
    </>
  );
};

const AuthStoreForm = () => {
  const storesData = [
    {
      id: 1,
      name: "Trendyol",
      image: "/assets/images/market/market-logo-trendyol.jpg",
      isActive: true,
    },
    /*     {
      id: 2,
      name: "Hepsiburada",
      image: "/assets/images/market/market-logo-hepsiburada.jpg",
      isActive: false,
    },
    {
      id: 3,
      name: "N11",
      image: "/assets/images/market/market-logo-n11.jpg",
      isActive: false,
    }, */
  ];
  return (
    <div className="store-images">
      {storesData.map((store, index) => (
        <div
          className={`store-image ${store.isActive && "active"}`}
          key={index}
        >
          <img src={store.image} alt={store.name} />
        </div>
      ))}
    </div>
  );
};

export default AuthSummary;
