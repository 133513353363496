import Loading from "components/shared-components/loading-components";
import React from "react";

const PaymentClose = () => {
  return (
    <div className="flex justify-center items-center">
      <Loading show={true} />
    </div>
  );
};

export default PaymentClose;
