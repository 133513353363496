import api from "./ApiService";
import helperService from "./HelperService";

const reportService = {};

reportService.Order = {
  OrderTotalByStatus: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderTotalByStatus?${queryString}`
    );
    return data;
  },
  OrderGroupedByStatus: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderGroupedByStatus?${queryString}`
    );
    return data;
  },
  OrderGroupedByStores: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderGroupedByStores?${queryString}`
    );
    return data;
  },
  OrderTotalByYear: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderTotalByYear?${queryString}`
    );
    return data;
  },
  OrderTotalByMonth: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderTotalByMonth?${queryString}`
    );
    return data;
  },
  DraftOrderCount: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/orders/report/OrderToWiseGroupedByDraftStatus?${queryString}`
    );
    return data;
  },
};

reportService.Product = {
  TopSellerProducts: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/aggregator/report/TopSellerProducts?${queryString}`
    );
    return data;
  },
  ProductGroupedBySource: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/products/report/ProductGroupedBySource?${queryString}`
    );
    return data;
  },
  ProductGroupedByStore: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/products/report/ProductGroupedByStore?${queryString}`
    );
    return data;
  },
};

reportService.Ticket = {
  TicketGroupedByStatus: async (params) => {
    let queryString = helperService.generateQueryString(params);
    const data = await api.get(
      `/supports/report/TicketGroupedByStatus?${queryString}`
    );
    return data;
  },
};

reportService.Sales = {
  GetBestsellers: async (params) => {
    let queryString = helperService.generateQueryString(params);
    return await api.get(
      `/orders/report/TopSellerProductsPaged?${queryString}`
    );
  },
  SalesByCategory: async (params) => {
    let queryString = helperService.generateQueryString(params);
    return await api.get(
      `/orders/report/BestSellerProductsByCategoryPaged?${queryString}`
    );
  },
  DownloadBestseller: async (startDate, endDate) => {
    return await api.getFile(
      `orders/report/BestSellerProductsExcell?StartDate=${startDate}&EndDate=${endDate}
    `
    );
  },
  DownloadSalesByCategory: async (startDate, endDate) => {
    return await api.getFile(
      `orders/report/BestSellerProductsByCategoryExcell?StartDate=${startDate}&EndDate=${endDate}
    `
    );
  },
  DownloadSalesByBrand: async (startDate, endDate) => {
    return await api.getFile(
      `orders/report/BestSellerProductsByBrandExcell?StartDate=${startDate}&EndDate=${endDate}
    `
    );
  },
  SalesByBrand: async (params) => {
    let queryString = helperService.generateQueryString(params);
    return await api.get(
      `/orders/report/BestSellerProductsByBrandPaged?${queryString}`
    );
  },
  DownloadSalesEndorsement: async (startDate, endDate) => {
    return await api.getFile(
      `/orders/report/SalesOrderExcell?StartDate=${startDate}&EndDate=${endDate}
    `
    );
  },
  SalesEndorsement: async (params) => {
    let queryString = helperService.generateQueryString(params);
    return await api.get(`/orders/report/SalesOrderList?${queryString}`);
  },
};

export default reportService;
