import {
  SET_MARKETPLACE,
  SET_STORE,
  SET_PRODUCT_STATUS,
  SET_IS_STORE_CONNECTED, 
} from "redux/constants/shared/marketplace";

export const setMarketplace = (marketplace) => {
  return {
    type: SET_MARKETPLACE,
    marketplace,
  };
};

export const setStores = (stores) => {
  return {
    type: SET_STORE,
    stores,
  };
};

export const setProductStatus = (productStatus) => {
  return {
    type: SET_PRODUCT_STATUS,
    productStatus,
  };
};

export const setStoreIsConnected = (Id, IsConnected) => {
  return {
    type: SET_IS_STORE_CONNECTED,
    Id,
    IsConnected,
  };
}; 